<template>
  <div>
    <MedicalConnection class="mt-4" />
    <MembershipUpsell v-if="shouldShowMembershipUpsellBox" class="mt-4" />
    <div
      v-if="storedUser?.hasMedicalConnection"
      class="mt-4 flex grid-cols-2 flex-col sm:grid sm:gap-4 lg:grid lg:auto-cols-fr lg:grid-cols-3 lg:gap-4"
    >
      <RedirectCard
        v-if="hasNoMembership"
        class="mt-2 sm:mt-0"
        variant="yellow"
        to="https://haelsi.at/abo?mtm_campaign=portal"
      >
        <MembershipIllustration aria-hidden="true" />
        <template #text>Mitglied werden und 25&nbsp;&percnt; sparen</template>
      </RedirectCard>
      <RedirectCard class="mt-2 sm:mt-0" variant="petrol" :to="{ name: 'prescriptions' }">
        <FileIllustration aria-hidden="true" />
        <template #text> Rezepte</template>
      </RedirectCard>
      <RedirectCard class="mt-2 sm:mt-0" variant="ivory" :to="{ name: 'doctorNotes' }">
        <EnvelopeIllustration aria-hidden="true" />
        <template #text> Arztbriefe</template>
      </RedirectCard>
      <RedirectCard class="mt-2 sm:mt-0" variant="cloud" :to="{ name: 'referrals' }">
        <UtensilIllustration aria-hidden="true" />
        <template #text> Überweisungen</template>
      </RedirectCard>
      <RedirectCard class="mt-2 sm:mt-0" variant="ivory" :to="{ name: 'invoices' }">
        <InvoiceIllustration aria-hidden="true" />
        <template #text> Rechnungen</template>
      </RedirectCard>
      <RedirectCard class="mt-2 sm:mt-0" variant="petrol" :to="{ name: 'membership' }">
        <PersonIllustration aria-hidden="true" />
        <template #text> Profil</template>
      </RedirectCard>
      <RedirectCard
        v-if="isUserMember"
        class="mt-2 sm:mt-0"
        variant="yellow"
        :to="{ name: 'medicine-delivery' }"
      >
        <PillIllustration aria-hidden="true" />
        <template #text> 10 % Rabatt auf rezeptfreie Medikamente</template>
      </RedirectCard>
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useRoute } from 'vue-router';

import EnvelopeIllustration from '@/assets/svg/envelopeIllustration.svg';
import FileIllustration from '@/assets/svg/fileIllustration.svg';
import InvoiceIllustration from '@/assets/svg/invoiceIllustration.svg';
import MembershipIllustration from '@/assets/svg/membershipIllustration.svg';
import PillIllustration from '@/assets/svg/pillIllustration.svg';
import PersonIllustration from '@/assets/svg/personIllustration.svg';
import UtensilIllustration from '@/assets/svg/utensilIllustration.svg';
import MedicalConnection from '@/components/MedicalConnection.vue';
import MembershipUpsell from '@/components/MembershipUpsell.vue';
import RedirectCard from '@/components/RedirectCard.vue';
import { useCustomToast } from '@/composables/useCustomToast';
import { useUserPreferencesStore } from '@/stores/user-preferences.store';
import { useUserStore } from '@/stores/user.store';
import { MedicalConnectionStatus } from '@/types/api/MedicalConnectionStatus.enum';
import { MembershipStatus } from '@/types/api/MembershipStatus.enum';

const userStore = useUserStore();
const { user: storedUser } = storeToRefs(userStore);
const { showSuccessToast } = useCustomToast();

const userPreferencesStore = useUserPreferencesStore();
const shouldShowMembershipUpsellBox = computed(() => {
  const user = storedUser.value;

  if (user === null) {
    return false;
  }

  return (
    user.hasMedicalConnection === true &&
    user.medicalConnectionStatus === MedicalConnectionStatus.CONNECTED &&
    user.membership === MembershipStatus.NON_MEMBERS &&
    userPreferencesStore.shouldShowMembershipUpsellBox
  );
});

const route = useRoute();
const isTokenVerified = route.query.verified === 'true';

if (isTokenVerified) {
  showSuccessToast({
    title: 'Deine E-Mail Adresse wurde erfolgreich bestätigt.',
    text: 'Du kannst jetzt deine Dokumente einsehen.',
  });
}

const isUserMember = computed(
  () => !(storedUser?.value?.membership === MembershipStatus.NON_MEMBERS),
);

const hasNoMembership = storedUser.value?.membership === MembershipStatus.NON_MEMBERS;
</script>
